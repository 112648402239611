import { useTheme } from '@emotion/react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { ASSETS_BASE_URL } from 'src/api/concierge';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { ReactComponent as SaveYourFriendsASpotIcon } from 'src/assets/svg/save-your-friends-a-spot.svg';
import { ReactComponent as SaveYourSpotIcon } from 'src/assets/svg/save-your-spot.svg';
import { ReactComponent as SwapYourSpotIcon } from 'src/assets/svg/swap-your-spot.svg';
import { Image } from 'src/components/image';
import { routes } from 'src/constants/routes';
import { useGetActiveBooking } from 'src/features/bookings';

import { RebookStatus } from '../../api/constants';
import { useGetRebookingPhase } from '../../hooks/use-get-rebooking-room-phase';
import { createTransactionsLink } from '../../utils/create-transactions-link';
import { getPhases } from '../../utils/get-phases';

import { RebookingLink } from './rebooking-link';
import { RebookingLinkSkeleton } from './rebooking-link-skeleton';
import { RebookingLinksWrap } from './rebooking-links-wrap';

export const RebookingLinks: FC = () => {
    const { t } = useTranslation();

    const { data: booking } = useGetActiveBooking();
    const { data: phasesInfo, isLoading, isError } = useGetRebookingPhase();
    const theme = useTheme();

    const phases = phasesInfo?.phases;
    const rebookStatus = phasesInfo?.rebook_status;

    if (isLoading) {
        return (
            <RebookingLinksWrap>
                <RebookingLinkSkeleton />
                <RebookingLinkSkeleton />
            </RebookingLinksWrap>
        );
    }

    if (isError || !phasesInfo.is_available || rebookStatus === RebookStatus.NotAllowed) {
        return <Navigate to={routes.home} />;
    }

    const { keep } = getPhases(phases!);

    // to be used for when the cards are active
    const keepLink = createTransactionsLink({
        room_code: booking!.room,
    });

    //to be used for swap it {routes.rebooking.swap}

    const jsx = [
        <RebookingLink
            key="refer"
            title="Save your Friends a spot."
            subtitle={t('rebookers-card_refer_friend_coming_soon_availability')}
            body={t('rebookers-card_refer_friend_coming_soon_description')}
            cta={t('rebookers-card_refer_friend_coming_soon_cta')}
            link="https://vitastudent.typeform.com/to/LtgXvBHz?typeform-source=my-account"
            label={
                <Box
                    css={{
                        position: 'absolute',
                        [theme.breakpoints.up('lg')]: {
                            top: 0,
                        },
                        bottom: 0,
                        zIndex: 1,
                    }}
                    textAlign="center"
                    width="100%"
                    paddingTop="19px"
                >
                    <Typography
                        variant="h2"
                        css={{
                            color: '#EC619F',
                        }}
                        paddingBottom="14px"
                    >
                        {t('rebookers-card_refer_friend_coming_soon_weeks_reward')}
                    </Typography>
                    <Box px="19px" pb="30px" display={{ xs: 'inherit', lg: 'none' }}>
                        <Button
                            variant="contained"
                            css={{
                                backgroundColor: '#EC619F',
                                color: 'black',
                                borderRadius: '0px',
                                borderColor: 'black',
                                paddingTop: '16px',
                                paddingBottom: '16px',
                                border: '1px solid black',
                            }}
                            fullWidth
                            disableElevation
                            href="https://vitastudent.typeform.com/to/LtgXvBHz?typeform-source=my-account"
                            target="_blank"
                        >
                            <Typography>{t('rebookers-card_refer_friend_coming_soon_cta')}</Typography>
                        </Button>
                    </Box>
                </Box>
            }
            image_srcset={{
                lg: `${ASSETS_BASE_URL}/images/save_your_friends_banner_1366.jpeg`,
                xs: `${ASSETS_BASE_URL}/images/save_your_friends_banner_375.jpeg`,
            }}
            disabled={false}
            ga_name="Refer Card Option Card Click"
            cardColor="#EC619F"
            titleIcon={<SaveYourFriendsASpotIcon />}
            caption={
                null
            }
        />,
        <RebookingLink
            key="swap"
            title={t('rebookers_swap-it-card_title')}
            subtitle={t('rebookers-card_swap_it_coming_soon_availability')}
            body={<Typography>{t('rebookers-card_swap_it_coming_soon_description')}</Typography>}
            link="https://www.vitastudent.com/en/rebookers"
            label={
                <Box
                    css={{
                        position: 'absolute',
                        [theme.breakpoints.up('lg')]: {
                            top: 0,
                        },
                        bottom: 0,
                        zIndex: 1,
                    }}
                    textAlign={{ xs: 'right', lg: 'center' }}
                    width="100%"
                    paddingTop="19px"
                >
                    <Typography
                        paddingRight="19px"
                        variant="overlineMedium"
                        fontWeight={700}
                        css={{
                            color: '#71C1A9',
                        }}
                    >
                        {t('rebookers-card_swap_it_coming_soon_up_to')}{' '}
                        <Typography
                            variant="overlineMedium"
                            fontWeight={700}
                            fontSize="24px"
                            textTransform="uppercase"
                            paddingBottom="14px"
                        >
                            {t('rebookers-card_swap_it_coming_soon_weeks_free')}
                        </Typography>
                    </Typography>
                    <Box px="19px" pb="30px" display={{ xs: 'inherit', lg: 'none' }}>
                        <Button
                            variant="contained"
                            css={{
                                backgroundColor: '#71C1A9',
                                color: 'black',
                                borderRadius: '0px',
                                borderColor: 'black',
                                paddingTop: '16px',
                                paddingBottom: '16px',
                                border: '1px solid black',
                            }}
                            fullWidth
                            disableElevation
                            href="https://www.vitastudent.com/en/rebookers"
                            target="_blank"
                        >
                            <Typography>{t('rebookers-card_swap_it_coming_soon_cta')}</Typography>
                        </Button>
                    </Box>
                </Box>
            }
            image_srcset={{
                lg: `${ASSETS_BASE_URL}/images/swap_it_banner_1366.jpeg`,
                xs: `${ASSETS_BASE_URL}/images/swap_it_banner_375.jpeg`,
            }}
            disabled={false}
            ga_name="Swap Room Option Card Click"
            cardColor="#71C1A9"
            cta={t('rebookers-card_swap_it_coming_soon_cta')}
            titleIcon={<SwapYourSpotIcon />}
            caption={
                <Typography variant="overlineRegular">
                    {t('rebookers-card_swap_it_coming_soon_up_to')}{' '}
                    <Typography
                        variant="overlineMedium"
                        fontWeight={700}
                        fontSize="24px"
                        textTransform="uppercase"
                    >
                        {t('rebookers-card_swap_it_coming_soon_weeks_free')}
                    </Typography>
                </Typography>
            }
        />,
    ];

    if (!keep?.active) {
        jsx.reverse();
    }

    const RebookInfo = (
        <Fragment>
            <Box
                css={{
                    display: 'block',
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '16px',
                }}
                border="1px solid black"
                display={{ xs: 'none', lg: 'inherit' }}
            >
                <Stack direction="row-reverse">
                    <Box position="relative" flex={1} display={{ xs: 'none', lg: 'inherit' }}>
                        <Image
                            srcSet={{
                                xl: `${ASSETS_BASE_URL}/images/rebooker_main_banner_1920.jpeg`,
                                lg: `${ASSETS_BASE_URL}/images/rebooker_main_banner_1366.jpeg`,
                                md: `${ASSETS_BASE_URL}/images/rebooker_main_banner_768.jpeg`,
                                xs: `${ASSETS_BASE_URL}/images/rebooker_main_banner_375.jpeg`,
                            }}
                            alt="Rebookers banner"
                        />

                        <Typography
                            position="absolute"
                            top="24px"
                            left="23px"
                            variant="h2"
                            textTransform="uppercase"
                            display={{ xs: 'inherit', lg: 'none' }}
                        >
                            {t('rebookers-card_how_it_works_title')}
                        </Typography>
                    </Box>

                    <Box flex={1} position="relative">
                        <Stack
                            rowGap="31px"
                            marginLeft={{ xs: '30px', lg: '40px' }}
                            marginTop={{ xs: '40px', lg: '40px' }}
                            marginBottom={{ xs: '68px', lg: '68px' }}
                            marginRight={{ xs: '73px', lg: '73px' }}
                        >
                            <Stack direction="row" justifyContent="space-between">
                                <Box width="fit-content" textAlign="right">
                                    <Typography
                                        variant="h1"
                                        color="black"
                                        textTransform="uppercase"
                                    >
                                        {t('rebookers-card_coming-soon_title')}
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        color="black"
                                        textAlign="right"
                                        width="100%"
                                    >
                                        {t('rebookers-card_coming-soon_years')}
                                    </Typography>
                                </Box>
                                <Box
                                    textAlign="right"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        variant="h2"
                                        color="black"
                                        textTransform="uppercase"
                                    >
                                        {t('rebookers-card_how_it_works_title')}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Typography variant="overlineRegular" color="black" fontSize="18px">
                                {t('rebookers-card_how_it_works_description')}
                            </Typography>
                            <br />
                            <Button variant="text" href="https://www.vitastudent.com/en/rebookers">
                                <Typography
                                    color={theme.palette.primary.main}
                                    css={{ textDecoration: 'underline' }}
                                >
                                    {t('rebookers-card_how_it_works_cta')}
                                </Typography>
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box display={{ xs: 'inherit', lg: 'none' }}>
                <Accordion elevation={1}>
                    <AccordionSummary
                        css={{
                            backgroundColor: 'white',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                        expandIcon={<ArrowIcon color="black" />}
                    >
                        <Typography variant="h2" textTransform="uppercase">
                            {t('rebookers-card_how_it_works_title')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        css={{
                            backgroundColor: 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        }}
                    >
                        <Box width="fit-content" textAlign="right" pb="26px">
                            <Typography variant="h1" color="black" textTransform="uppercase">
                                {t('rebookers-card_how_it_works_caption')}
                            </Typography>
                            <Typography variant="h3" color="black" textAlign="right" width="100%">
                                {t('rebookers-card_how_it_works_years')}
                            </Typography>
                        </Box>
                        <Typography variant="overlineMedium" fontSize="16px">
                            {t('rebookers-card_how_it_works_description')}
                        </Typography>
                        <br />
                        <br />
                        <Button
                            variant="text"
                            href="https://www.vitastudent.com/en/rebookers"
                            css={{ paddingLeft: 0 }}
                        >
                            <Typography
                                color={theme.palette.primary.main}
                                css={{ textDecoration: 'underline' }}
                            >
                                {t('rebookers-card_how_it_works_cta')}
                            </Typography>
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Fragment>
    );
    const SaveYourSpotInfo = (
        <Box
            css={{
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
                marginBottom: '16px',
            }}
        >
            <Stack direction={{ xs: 'column-reverse', lg: 'row' }}>
                <Box position="relative" flex={1}>
                    <Image
                        srcSet={{
                            xl: `${ASSETS_BASE_URL}/images/save_your_spot_banner_1920.jpeg`,
                            lg: `${ASSETS_BASE_URL}/images/save_your_spot_banner_1366.jpeg`,
                            md: `${ASSETS_BASE_URL}/images/save_your_spot_banner_768.jpeg`,
                            xs: `${ASSETS_BASE_URL}/images/save_your_spot_banner_375.jpeg`,
                        }}
                        css={{
                            minHeight: '268px',
                            [theme.breakpoints.up('lg')]: {
                                minHeight: '459px',
                            },
                        }}
                        alt="Rebookers banner"
                    />

                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        textTransform="uppercase"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems={{ xs: 'flex-start', lg: 'center' }}
                        height="100%"
                        width="100%"
                        px={{ xs: '20px', lg: '110px' }}
                        py={{ xs: '30px', lg: '45px' }}
                        display="flex"
                    >
                        <Box
                            paddingX={{ xs: '0px', lg: '70px' }}
                            paddingBottom={{ xs: '30px', lg: '45px' }}
                            paddingTop={{ xs: '30px', lg: '45px' }}
                            mb={{ xs: '0px', lg: '40px' }}
                            color="#F6E946"
                            css={{
                                [theme.breakpoints.up('lg')]: {
                                    border: '1px solid #F6E946',
                                    textAlign: 'center',
                                    width: 'inherit',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                },
                                textAlign: 'left',
                                width: '188px',
                            }}
                        >
                            <Typography variant="overlineMedium" fontSize="24px">
                                {t('rebookers-card_keep_it_coming_soon_title')}
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            css={{
                                backgroundColor: '#F6E946',
                                color: 'black',
                                borderRadius: '0px',
                                borderColor: 'black',
                                paddingTop: '16px',
                                paddingBottom: '16px',
                                border: '1px solid black',
                            }}
                            fullWidth
                            disableElevation
                            href="https://www.vitastudent.com/en/rebookers"
                            target="_blank"
                        >
                            <Typography>{t('rebookers-card_keep_it_coming_soon_cta')}</Typography>
                        </Button>
                    </Box>
                </Box>

                <Box flex={1} css={{ background: '#F6E946' }} position="relative">
                    <Box
                        paddingTop="19px"
                        paddingBottom="6px"
                        borderBottom="1px solid black"
                        marginX="30px"
                        textAlign="right"
                    >
                        <Typography
                            variant="overlineRegular"
                            display={{ xs: 'none', lg: 'inherit' }}
                        >
                            {t('rebookers-card_keep_it_coming_soon_up_to')}{' '}
                            <Typography variant="overlineMedium" fontWeight={700} fontSize="24px">
                                {t('rebookers-card_keep_it_coming_soon_weeks_free')}
                            </Typography>
                        </Typography>
                    </Box>
                    <Stack
                        rowGap="31px"
                        marginLeft={{ xs: '30px', lg: '55px' }}
                        marginTop={{ xs: '40px', lg: '40px' }}
                        marginBottom={{ xs: '68px', lg: '68px' }}
                        marginRight={{ xs: '30px', lg: '55px' }}
                    >
                        <Stack
                            direction="row"
                            justifyContent={{ xs: 'space-between', lg: 'center' }}
                        >
                            <Box textAlign="center">
                                <SaveYourSpotIcon />
                            </Box>
                            <Box
                                display={{ xs: 'flex', lg: 'none' }}
                                alignItems="flex-start"
                                pb="18px"
                                pt="33px"
                                textAlign="right"
                            >
                                <Typography variant="overlineRegular">
                                    {t('rebookers-card_keep_it_coming_soon_up_to')}{' '}
                                    <Typography
                                        variant="overlineMedium"
                                        fontWeight={700}
                                        fontSize="24px"
                                        textTransform="uppercase"
                                    >
                                        {t('rebookers-card_keep_it_coming_soon_weeks_free')}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Stack>
                        <Typography
                            variant="overlineMedium"
                            color="black"
                            fontSize="18px"
                            textAlign="center"
                        >
                            {t('rebookers-card_keep_it_coming_soon_description')}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );

    return (
        <RebookingLinksWrap RebookingInfo={RebookInfo} saveYourSpot={SaveYourSpotInfo}>
            {jsx}
        </RebookingLinksWrap>
    );
};
